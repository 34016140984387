import React from 'react'
import TopFloater from './TopFloater'
import '../../layouts/guestLayout/guestLayout.less'
import { useTranslation } from 'react-i18next'

interface Props {
  isSignup?: boolean
}

const NotFound = ({ isSignup }: Props) => {
  const { t } = useTranslation('guest')
  return (
    <>
      <TopFloater isSignup={isSignup} />
      <div className="login-box">{t('notFound.message')}</div>
    </>
  )
}

export default NotFound
