import { createSlice } from '@reduxjs/toolkit'
import { ChatSettingsState } from '../../types/public.type'
import { publicApi } from '../api/pubic.api'

export const initialState: ChatSettingsState = {
  chatSettings: {},
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(publicApi.endpoints?.getChatConfig.matchFulfilled, (state, action) => {
      state.chatSettings = action.payload.chatSettings
    })
  }
})

export default chatSlice.reducer
