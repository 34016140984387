import React, { useCallback, useMemo, useState } from 'react'
import TopFloater from '../guest/TopFloater'
import { useAppSelector } from '../../hooks/appHook'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useGetAccountQuery } from '../../store/api/account.api'
import StepsIndicator from './components/StepsIndicator/StepsIndicator'
import CardHeader from './components/CardHeader/CardHeader'
import StepsForm from './components/StepsForm/StepsForm'
import '../../layouts/guestLayout/guestLayout.less'
import './onboarding.less'
import { useTranslation } from 'react-i18next'

const Onboarding = () => {
  const { t } = useTranslation('onboarding')

  const steps = [
    {
      title: t('onboarding.steps.companyName'),
    },
    {
      title: t('onboarding.steps.companySize'),
    },
    {
      title: t('onboarding.steps.useCase'),
    },
  ]

  const stepsLength = steps.map((_el, i) => i + 1)

  /** Storage */
  const {
    user: { name, id },
  } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const { error } = useGetAccountQuery(undefined, { skip: !id })

  /** State */
  const [currentStep, setCurrentStep] = useState(0)

  /** Response message handlers */
  useErrorMessage(t('onboarding.error'), error)

  const stepsTitle = useMemo(() => {
    return steps[currentStep]?.title
  }, [currentStep])

  const movePrevStep = useCallback(() => {
    setCurrentStep((prev) => prev - 1)
  }, [])

  const moveNextStep = useCallback(() => {
    setCurrentStep((prev) => prev + 1)
  }, [])

  return (
    <>
      <TopFloater isSignup />
      <div className="poll-box">
        <div className="poll-wrapper">
          <CardHeader userName={name} />
          <StepsIndicator currentStep={currentStep} items={stepsLength} />
          <StepsForm
            stepTitle={stepsTitle}
            currentStep={currentStep}
            moveNextStep={moveNextStep}
            movePrevStep={movePrevStep}
          />
        </div>
      </div>
    </>
  )
}

export default Onboarding
