import React from 'react'
import Logo from '../../components/UI/Logos/Logo'
import './publicChat.less'
import { Button } from 'antd'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'

const handleOnPressVisitWebsite = () => {
  window.open('https://ioni.ai/', '_blank')
}

const handleOnPressIONI = () => {
  window.location.reload()
}

const ChatHeader = () => {
  return (
    <div className="chat-header">
      <div id="chat-header-inner-container">
        <Button id='logo-btn' type="link" onClick={handleOnPressIONI}>
          <Logo name={'ioniBlack'} width={'100%'} height={'100%'} />
        </Button>
        <TransparentButton onClick={handleOnPressVisitWebsite} title='visit website'/>
      </div>
    </div>
  )
}

export default ChatHeader
