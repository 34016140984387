import React from 'react'
import Logo from '../UI/Logos/Logo'
import { useTranslation } from 'react-i18next'

export default function SingInInfoBox() {
  const { t } = useTranslation('components')

  return (
    <div className="info-box">
      <h1>{t('singInInfoBox.title')}</h1>
      <p className="info-box-sub-title">{t('singInInfoBox.subtitle')}</p>
      <div className="info-badge-box">
        <a
          href="https://www.producthunt.com/posts/ioni?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ioni"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=408486&theme=light&period=daily"
            alt="ioni - Boost your customer support productivity with ChatGPT-4 | Product Hunt"
            style={{ width: '250px', height: '54px' }}
            width="250"
            height="54"
          />
        </a>
        <a
          href="https://www.producthunt.com/posts/ioni?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ioni"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=408486&theme=light&period=weekly"
            alt="ioni - Boost your customer support productivity with ChatGPT-4 | Product Hunt"
            style={{ width: '250px', height: '54px' }}
            width="250"
            height="54"
          />
        </a>
      </div>
      <div>
        <p>
          <a href="https://springsapps.com/" target="_blank" rel="noreferrer" className="springs">
            {t('singInInfoBox.poweredBy')}
            <Logo name="springs" style={{ display: 'flex', alignItems: 'center' }} />
          </a>
        </p>
      </div>
    </div>
  )
}
