import { createApi } from '@reduxjs/toolkit/query/react'
import { LocalStorage } from 'ts-localstorage'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { Integration } from '../../types/integration.type'
import { ACCESS_TOKEN } from '../../config/consts'

export const integrationApi = createApi({
  reducerPath: 'integrationApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Integrations', 'Integration'], // Define tags
  endpoints: (builder) => ({
    getIntegrationList: builder.query<Array<Integration>, void>({
      query: () => ({
        url: `integrations`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: (result) =>
        result
          ? // If the query returned a result, tag each integration
          [
            ...result.map(({ id }) => ({ type: 'Integration', id } as const)),
            { type: 'Integrations', id: 'LIST' },
          ]
          : [{ type: 'Integrations', id: 'LIST' }],
    }),
    getIntegrationById: builder.query<Integration, { id: string }>({
      query: ({ id }) => ({
        url: `integrations/${id}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: (result, error, { id }) => [{ type: 'Integration', id }],
    }),
    deleteIntegration: builder.mutation<void, string>({
      query: (id) => ({
        url: `integrations/${id}`,
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      // Invalidate the integration list and the specific integration by ID
      invalidatesTags: (result, error, id) => [
        { type: 'Integrations', id: 'LIST' },
        { type: 'Integration', id },
      ],
    }),
  }),
})

export const {
  useGetIntegrationListQuery,
  useLazyGetIntegrationByIdQuery,
  useDeleteIntegrationMutation,
} = integrationApi
