import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Checkbox, Form, Input, Space } from 'antd'
import { AccountRolesEnum } from '../../config/rolesEnum'
import { IAccount } from '../../types/account.type'
import { PROFILE_ROUTE } from '../../config/consts'
import { useAppSelector } from '../../hooks/appHook'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useUpdateProfileInfoMutation } from '../../store/api/profile.api'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { useAccountUpdateMutation } from '../../store/api/account.api'
import './editProfile.less'
import './profile.less'
import Loading from '../../components/UI/Loading'
import { minLengthValidator, requiredValidator } from '../../helpers/validators'
import { omit } from 'lodash'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import { useTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

const ProfileEdit = () => {
  const { t } = useTranslation('profileEdit')
  const navigate = useNavigate()

  const [formAccount] = Form.useForm()
  const [formProfile] = Form.useForm()

  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false)
  const [isEmailChange, setIsEmailChange] = useState<boolean>(false)
  const [
    userUpdate,
    { data: userUpdateData, error: errorUserUpdate, isSuccess: isUserSuccess, isLoading: isUpdatingUser },
  ] = useUpdateProfileInfoMutation()
  const [accountUpdate, { error: errorAccountUpdate, isSuccess: isAccountSuccess, isLoading: isUpdatingAccount }] =
    useAccountUpdateMutation()

  const onFinishAccount = (values: IAccount) => {
    accountUpdate({ ...values })
  }

  const onFinishProfile = (values: { name: string; email: string; password: string; currentPassword: string }) => {
    userUpdate({ ...values })
  }

  useErrorMessage(t('messages.cantUpdateRecord'), errorUserUpdate || errorAccountUpdate)
  useSuccessMessage(t('messages.successfullyUpdated'), isAccountSuccess)
  useSuccessMessage(
    `${t('messages.successfullyUpdated')}${
      (userUpdateData as any)?.isEmailVerificationSent ? t('messages.verificationLinkSent') : ''
    }`,
    isUserSuccess,
  )

  useEffect(() => {
    if (isUserSuccess || isAccountSuccess) {
      navigate(PROFILE_ROUTE)
    }
  }, [isUserSuccess, isAccountSuccess])

  if (!user.name) {
    return (
      <div id="loading-container">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <AiHelperHeader title={t('title')} paragraph="" />
      <div className="profile-wrapper">
        {user.accountRole === AccountRolesEnum.ADMIN && (
          <>
            <h2>{t('account')}</h2>
            <Form {...formItemLayout} form={formAccount} initialValues={account} onFinish={onFinishAccount}>
              <Form.Item
                name="name"
                label={t('name')}
                rules={[{ required: true, validator: requiredValidator(t('validators.enterAccountName')) }]}
              >
                <Input />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Space>
                  <Button type="primary" loading={isUpdatingAccount} htmlType="submit">
                    {t('saveAccountInfo')}
                  </Button>
                  <Button onClick={() => navigate(PROFILE_ROUTE)}>{t('backToProfile')}</Button>
                </Space>
              </Form.Item>
            </Form>
          </>
        )}
        <h2>{t('profile')}</h2>
        <Form {...formItemLayout} form={formProfile} initialValues={omit(user, 'email')} onFinish={onFinishProfile}>
          <Form.Item
            name="name"
            label={t('name')}
            rules={[{ required: true, message: t('validators.enterYourName'), whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="passwordEmail" valuePropName="checked" {...tailFormItemLayout}>
            <Checkbox
              checked={isEmailChange}
              onClick={() => {
                setIsEmailChange((prevState) => !prevState)
                setIsPasswordChange(false)
                formProfile.resetFields(['passwordChange'])
              }}
            >
              {t('changeEmail')}
            </Checkbox>
          </Form.Item>
          <Form.Item name="passwordChange" valuePropName="checked" {...tailFormItemLayout}>
            <Checkbox
              checked={isPasswordChange}
              onClick={() => {
                setIsPasswordChange((prevState) => !prevState)
                setIsEmailChange(false)
                formProfile.resetFields(['passwordEmail'])
              }}
            >
              {t('changePassword')}
            </Checkbox>
          </Form.Item>

          {isEmailChange && (
            <>
              <Form.Item
                name="email"
                label={t('newEmail')}
                rules={[
                  {
                    type: 'email',
                    message: t('validators.invalidEmail'),
                  },
                  {
                    required: true,
                    message: t('validators.enterYourEmail'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}

          {(isEmailChange || isPasswordChange) && (
            <Form.Item
              name="currentPassword"
              label={t('currentPassword')}
              rules={[{ required: true, validator: requiredValidator(t('validators.currentPasswordRequired')) }]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              isPasswordChange && (
                <>
                  <Form.Item
                    name="password"
                    label={t('newPassword')}
                    hasFeedback={!!getFieldValue('password')}
                    rules={[
                      { validator: minLengthValidator(8, t('validators.password')) },
                      { required: true, validator: requiredValidator(t('validators.passwordRequired')) },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label={t('confirmNewPassword')}
                    dependencies={['password']}
                    hasFeedback={!!getFieldValue('confirmPassword')}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error(t('validators.passwordMismatch')))
                        },
                      }),
                      { required: true, validator: requiredValidator(t('validators.confirmPasswordRequired')) },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Space>
              <Button type="primary" loading={isUpdatingUser} htmlType="submit">
                {t('saveProfileInfo')}
              </Button>
              <Button onClick={() => navigate(PROFILE_ROUTE)}>{t('backToProfile')}</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default ProfileEdit
