import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { useEffect, useState } from 'react'
import { fetchAppConfig } from '../api/fetchAppConfig'
import { AppFeaturesConfig, AppLocalizationConfig, AppThemeConfig, AppConfig } from '../types/appConfig.interface'

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppConfig = () => {
  const [appTheme, setAppTheme] = useState<AppThemeConfig | undefined>(undefined)
  const [appFeatures, setAppFeatures] = useState<AppFeaturesConfig | undefined>(undefined)
  const [appLocalization, setAppLocalization] = useState<AppLocalizationConfig | undefined>(undefined)
  const [appDomain, setAppDomain] = useState<string | undefined>(undefined)
  const [appName, setAppName] = useState<string | undefined>(undefined)
  const [appSupportMail, setAppSupportMail] = useState<string | undefined>(undefined)

  useEffect(() => {
    const loadAppConfig = async () => {
      const data: AppConfig = await fetchAppConfig()

      setAppTheme(data.theme)
      setAppFeatures(data.features)
      setAppLocalization(data.localization)
      setAppDomain(data.domain)
      setAppName(data.name)
      setAppSupportMail(data.supportMail)
    }

    loadAppConfig()
  }, [])

  return { appTheme, appFeatures, appLocalization, appDomain, appName, appSupportMail }
}
