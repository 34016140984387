import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/UI/Icons/Icons';
import { BulbOutlined } from '@ant-design/icons';
import {
  ACCOUNTS_ROUTE,
  AI_AGENT_ROUTE,
  CONVERSATIONS_ROUTE,
  HOME_ROUTE,
  KNOWLEDGE_BASE_ROUTE,
} from '../../config/consts'

export const sliderMenuItems = [
  {
    label: <Link to="">Home</Link>,
    key: '/',
    icon: <Icon name="infoCirclePlainDesigned" />,
    path: HOME_ROUTE,
    title: 'Home',
  },
  {
    label: <Link to="storage">Knowledge Base</Link>,
    key: '/storage',
    icon: <Icon name="addData" width={23} height={23} />,
    path: KNOWLEDGE_BASE_ROUTE,
    title: 'Knowledge Base',
  },
  {
    label: <Link to="agent">AI Agents</Link>,
    key: '/agent',
    icon: <Icon name="createAssistent" />,
    path: AI_AGENT_ROUTE,
    title: 'Create AI chatbot',
  },
  {
    label: <Link to="conversations">Conversations</Link>,
    key: '/conversations',
    icon: <Icon name="conversations" />,
    path: CONVERSATIONS_ROUTE,
    title: 'See conversations',
  },
]

export const adminItems = [
  {
    label: <Link to="admin/accounts">Accounts</Link>,
    key: 'admin/accounts',
    icon: <BulbOutlined />,
    path: ACCOUNTS_ROUTE,
    title: 'Accounts',
  },
]
