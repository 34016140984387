import React from 'react'
import { useAppConfig, useAppSelector } from '../../../../hooks/appHook'
import Icon from '../../../../components/UI/Icons/Icons'
import LinkCard from './components/LinkCard'
import { useTranslation } from 'react-i18next'

const Intro = () => {
  const { appName } = useAppConfig()
  const { account } = useAppSelector((state) => state.account)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { t } = useTranslation('homepage')

  const buttonsItems = [
    {
      redirectUrl: 'storage',
      title: t('intro.knowledgeBase'),
      icon: <Icon name="addData" width={32} height={30} />,
      description: t('intro.knowledgeBaseDescription'),
      children: (
        <>
          <span style={{ fontWeight: '700' }}>URL</span>, <span style={{ fontWeight: '700' }}>HTML</span> or{' '}
          <span style={{ fontWeight: '700' }}>text</span> options.
        </>
      ),
    },
    {
      redirectUrl: 'agent',
      title: t('intro.aiAgents'),
      icon: <Icon name="createAssistent" width={28} height={27} />,
      description: t('intro.aiAgentsDescription'),
    },
    {
      redirectUrl: 'conversations',
      title: t('intro.conversations'),
      icon: <Icon name="conversations" width={29} height={24} />,
      description: t('intro.conversationsDescription', { name: appName }),
    },
    {
      redirectUrl: `/c/${account.id}?${currentStorageId && `storageId=${currentStorageId}`}`,
      title: t('intro.testAndShareChat'),
      icon: <Icon name="share" />,
      description: t('intro.testAndShareChatDescription', { name: appName }),
    },
  ]

  return (
    <div id="buttons-container">
      <div className="buttons-group">
        {buttonsItems.map((item) => (
          <LinkCard
            key={item.title}
            redirectUrl={item.redirectUrl}
            title={item.title}
            icon={item.icon}
            description={item.description}
          >
            {item.children}
          </LinkCard>
        ))}
      </div>
    </div>
  )
}

export default Intro
