import React, { useCallback, useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import { useCreateStorageMutation } from '../../../../../../../../store/api/storage.api'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import { useAppStateActions } from '../../../../../../../../hooks/actions'
import { useErrorMessage } from '../../../../../../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../../../../../../hooks/useSuccessMessage'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import Icon from '../../../../../../../../components/UI/Icons/Icons'
import CreateStorageForm from '../../../CreateStorageForm'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CreateNewStorageModal = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('knowledgeBase')
  const [createStorageForm] = Form.useForm()

  const [language, setLanguage] = useState<string>('English')

  const [createStorage, { error: errorSendInvite, isSuccess, isLoading, data }] = useCreateStorageMutation()

  const { setIsCreateStorageModalOpen } = useAppStateActions()
  const { isCreateStorageModalOpen } = useAppSelector((state) => state.appState.modals)

  const onCompleteFormCreateStorage = useCallback(
    (values: any) => {
      const request = { ...values, language }
      createStorage({ body: request })
    },
    [language],
  )

  const onCancelFormCreateStorage = () => {
    setIsCreateStorageModalOpen(false)
    createStorageForm.resetFields()
  }

  useEffect(() => {
    if (isSuccess) {
      setIsCreateStorageModalOpen(false)
      createStorageForm.resetFields()
    }
  }, [isSuccess])

  useEffect(() => {
    if (data?.id) {
      const queryParams = new URLSearchParams(location.search)
      queryParams.set('storageId', data.id)

      /** Update the URL with the new query parameter, replacing the current entry without adding a new one to the history */
      navigate({ search: queryParams.toString() }, { replace: true })
    }
  }, [data])

  useErrorMessage(t('knowledgeBasePageHeader.createNewStorageModal.errorMessage'), errorSendInvite)
  useSuccessMessage(t('knowledgeBasePageHeader.createNewStorageModal.successMessage'), isLoading)

  return (
    <Modal
      title={t('knowledgeBasePageHeader.createNewStorageModal.title')}
      open={isCreateStorageModalOpen}
      footer={
        <AppPrimaryButton
          htmlType="submit"
          id="app-primary-button"
          loading={isLoading}
          onClick={createStorageForm.submit}
        >
          {t('knowledgeBasePageHeader.createNewStorageModal.createStorageButton')}
          <Icon name="buttonRight" />
        </AppPrimaryButton>
      }
      onCancel={onCancelFormCreateStorage}
    >
      <div>
        <CreateStorageForm
          storageForm={createStorageForm}
          language={language}
          setLanguage={setLanguage}
          onSubmitForm={onCompleteFormCreateStorage}
        />
      </div>
    </Modal>
  )
}

export default CreateNewStorageModal
