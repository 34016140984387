import React, { memo } from 'react'
import { Divider, List } from 'antd'
import SearchResultItem from '../../../../components/UI/AISearchResultItem/AISearchResultItem'
import { EditStorageKnowledgeRecord, StorageKnowledgeRecord } from '../../../../types/storage.type'

interface IProps {
  loading: boolean
  split?: boolean
  records: StorageKnowledgeRecord[]
  onPressEdit: (value: EditStorageKnowledgeRecord) => void
  onPressDelete: (record: StorageKnowledgeRecord) => void
}

const KnowledgeRecordsList = ({ loading, split = false, records, onPressEdit, onPressDelete }: IProps) => {
  return (
    <List
      pagination={{
        position: 'bottom',
        align: 'center',
        className: 'pagination',
      }}
      loading={loading}
      split={split}
      dataSource={records}
      renderItem={(item) => (
        <List.Item key={item.id} style={{ display: 'flex', flexDirection: 'column' }}>
          <Divider className="list-item-divider" />
          <SearchResultItem item={item} handleDeleteItem={onPressDelete} handleEditData={onPressEdit} />
        </List.Item>
      )}
    ></List>
  )
}

export default memo(KnowledgeRecordsList)
