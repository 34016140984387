import React from 'react'
import { Form, Input } from 'antd'
import Loading from '../../components/UI/Loading'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { useResetPasswordMutation } from '../../store/api/auth.api'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from '../../config/consts'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { minLengthValidator, requiredValidator } from '../../helpers/validators'
import { useTranslation } from 'react-i18next'

interface Props {
  code: string
  email: string
}

const ResetPassword = ({ code, email }: Props) => {
  const { t } = useTranslation('guest')
  const [resetPassword, { error, isSuccess, isLoading }] = useResetPasswordMutation()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const isInvitation = params.get('invitation')
  const navigate = useNavigate()

  if (!isInvitation) {
    useSuccessMessage(t('resetPassword.successMessage'), isSuccess)
  }
  useErrorMessage(t('resetPassword.errorMessage'), error)

  const onFinish = (values: { password: string }) => {
    resetPassword({ ...values, code, email, isInvitation: !!isInvitation })
  }

  if (isSuccess) {
    navigate(HOME_ROUTE)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="login-box login-box-forgot-password">
      <Form name="login-form" layout="vertical" onFinish={onFinish} className="form-forgot-password">
        <div>
          <p className="form-title forgot-password-form-title">
            {isInvitation
              ? t('resetPassword.headerTitle.completeRegistration')
              : t('resetPassword.headerTitle.createNewPassword')}
          </p>
        </div>
        {isInvitation && <p>{t('resetPassword.setupNewPassword')}</p>}
        <Form.Item
          name="password"
          rules={[
            { required: true, validator: requiredValidator(t('resetPassword.passwordRequired')) },
            { validator: minLengthValidator(8, t('resetPassword.passwordTooShort')) },
          ]}
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>
        <Form.Item className="form-item-forgot-password-button-wrapper">
          <AppPrimaryButton size="middle" type="primary" htmlType="submit" loading={isLoading}>
            {isInvitation ? t('resetPassword.submit') : t('resetPassword.reset')}
          </AppPrimaryButton>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ResetPassword
