import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { IKnowledgeBaseDocument } from '../../types/knowladgeBaseDocument.type'
import { IPaginationParams } from '../../types/pagination-params.type'

interface IGetDocuments {
  pagination: IPaginationParams
  storageId: string
}

interface ICreateDocumentData {
  storageId: string
  url?: string
  file?: {
    s3Uri: string
    publicUrl: string
  }
}

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Document', 'Documents'],
  endpoints: (builder) => ({
    getDocuments: builder.query<{ documents: IKnowledgeBaseDocument[]; count: number }, IGetDocuments>({
      query: ({ storageId, pagination: { page, pageSize } }) => ({
        url: `documents/`,
        method: 'GET',
        params: {
          storageId,
          page,
          pageSize,
        },
      }),
      providesTags: ['Documents'],
    }),
    getDocument: builder.query<IKnowledgeBaseDocument, string | null>({
      query: (id) => ({
        url: `documents/` + id,
        method: 'GET',
      }),
      providesTags: ['Document'],
    }),
    createDocument: builder.mutation<IKnowledgeBaseDocument, ICreateDocumentData>({
      query: (body) => ({
        url: `documents/` + body.storageId,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Document', 'Documents'],
    }),
    deleteDocument: builder.mutation<void, { documentId: string }>({
      query: ({ documentId }) => ({
        url: `documents/` + documentId,
        method: 'DELETE',
      }),
      invalidatesTags: ['Document', 'Documents'],
    }),
  }),
})

export const {
  useGetDocumentQuery,
  useGetDocumentsQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  // useLazyGetTicketsQuery,
  // useTicketUpdateMutation,
} = documentsApi
