import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { first } from 'lodash'
import { useAppSelector } from '../../../../hooks/appHook'
import AiHelperHeader from '../../../../components/AIHelperHeader/aiHelperHeader'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppStateActions } from '../../../../hooks/actions'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import { useGetAgentListQuery } from '../../../../store/api/agent.api'
import CreateNewAIAgentModal from '../CreateNewAIAgentModal/CreateNewAIAgentModal'
import { setCurrentAgentId } from '../../../../store/slices/agents.slice'
import TransparentButton from '../../../../components/UI/TransparentButton/TransparentButton'
import { useTranslation } from 'react-i18next'

const QUERY_AGENT_ID_NAME = 'agentId'

const AIAgentPageHeader = ({ title }: { title: string }) => {
  const { t } = useTranslation('aiAgent')
  const dispatch = useDispatch()
  const locate = useLocation()
  const navigate = useNavigate()

  const { setIsCreateAIAgentModalOpen } = useAppStateActions()
  const { account } = useAppSelector((state) => state.account)
  const { currentAgentId } = useAppSelector((state) => state.agents)
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { data: agents } = useGetAgentListQuery()

  const queryParams = new URLSearchParams(locate.search)

  const firstAgentInAgents = useMemo(() => {
    return first(agents) || null
  }, [agents])

  const selectOptions = useMemo(() => {
    return (
      agents?.map(({ id, name }) => {
        return {
          value: id,
          label: id === account.defaultAgentId ? `${name} (${t('aiAgentPageHeader.default')})` : name,
        }
      }) || []
    )
  }, [agents, account])

  const queryParamId = useMemo(() => {
    const queryId = queryParams.get(QUERY_AGENT_ID_NAME)
    if (queryId && selectOptions.map(({ value }) => value).includes(queryId)) {
      return queryId
    }
    return firstAgentInAgents?.id || ''
  }, [queryParams, firstAgentInAgents, selectOptions])

  useEffect(() => {
    dispatch(setCurrentAgentId(queryParamId))
  }, [queryParamId])

  useEffect(() => {
    if (firstAgentInAgents && !queryParams.has(QUERY_AGENT_ID_NAME)) {
      queryParams.set(QUERY_AGENT_ID_NAME, firstAgentInAgents.id)
      navigate({ search: queryParams.toString() }, { replace: true })
    }
  }, [queryParams, firstAgentInAgents])

  const onChangeSelect = useCallback((value: any) => {
    queryParams.set(QUERY_AGENT_ID_NAME, value)
    navigate({ search: queryParams.toString() }, { replace: true })
    dispatch(setCurrentAgentId(value))
  }, [])

  const onPressCreateAiAgent = useCallback(() => {
    setIsCreateAIAgentModalOpen(true)
  }, [])

  const handleOnPressTestAgent = useCallback(() => {
    if (account?.id && currentAgentId) {
      window.open(
        `${location.origin}/c/${account.id}?agentId=${currentAgentId}${
          currentStorageId && `&storageId=` + currentStorageId
        }`,
        '_blank',
      )
    }
  }, [account, currentAgentId])

  return (
    <div className="page-header">
      <CreateNewAIAgentModal />
      <AiHelperHeader title={title} paragraph="" />
      <div className="header-right-content">
        {agents?.length ? (
          <CustomSelect
            createButtonTitle={t('aiAgentPageHeader.createAIAgent')}
            onChange={onChangeSelect}
            options={selectOptions}
            initialStatus={queryParamId}
            key={queryParamId}
            onPressCreteButton={onPressCreateAiAgent}
          />
        ) : (
          <Button type="text" icon={<PlusOutlined />} onClick={onPressCreateAiAgent} className="create-storage-button">
            {t('aiAgentPageHeader.createNewAgent')}
          </Button>
        )}
        {agents?.length && (
          <TransparentButton
            onClick={handleOnPressTestAgent}
            title={t('aiAgentPageHeader.testAgent')}
            style={{ width: 150 }}
            className="transparent-button-small"
          />
        )}
      </div>
    </div>
  )
}

export default memo(AIAgentPageHeader)
