import React, { memo } from 'react';
import moment from 'moment';
import InfoTable from '../../../../../../components/InfoTable/InfoTable';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';

interface ConversationData {
  createdAt: string;
  conversationId: string;
  data: string;
}

const columns: ColumnsType<ConversationData> = [
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string, record: ConversationData) => {
      const formatDate = moment(value).format('D/MM/YYYY');
      const formatTime = moment(value).format('h:mm a');
      return (
        <Link to={`conversations/${record.conversationId}`} className="conversation-url">
          {formatDate} {formatTime}
        </Link>
      );
    },
  },
  {
    title: 'Unanswered Queries',
    dataIndex: 'data',
    key: 'data',
  },
];

interface UnansweredQuestionsProps {
  dataSource?: ConversationData[];
}

const UnansweredQuestions = ({ dataSource }: UnansweredQuestionsProps) => {
  return (
    <InfoTable<ConversationData>
      title="Unanswered Questions"
      dataSource={dataSource || []}
      columns={columns}
      tooltip="Here are the queries your Agent couldn't answer last week."
      pagination={{
        position: ['bottomCenter']
      }}
    />
  );
};

export default memo(UnansweredQuestions);
