import React from 'react'
import { Row } from 'antd'
import { LikeOutlined, SendOutlined, SearchOutlined } from '@ant-design/icons'
import UnansweredQuestions from './components/UnansweredQuestions/UnansweredQuestions'
import StatisticsCard from './components/StatisticsCard/StatisticsCard'
import LineChart from './components/LineChart/LineChart'
import { ConversationStats } from '../../../../types/conversation.type'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: ConversationStats
}

const Statistics = ({ data }: IProps) => {
  const { t } = useTranslation('homepage')

  return (
    <div className="container" style={{ maxWidth: 900 }}>
      <Row gutter={16} style={{ marginBottom: 30 }}>
        <StatisticsCard
          precision={0}
          value={data.count}
          suffix="/ week"
          label={t('statistics.conversations')}
          tooltipTitle={t('statistics.conversationsTooltip')}
          prefix={<SendOutlined />}
          isStyled={false}
        />
        <StatisticsCard
          precision={1}
          value={data.averageSatisfactory}
          suffix="/ 10"
          label={t('statistics.satisfactory')}
          tooltipTitle={t('statistics.satisfactoryTooltip')}
          prefix={<LikeOutlined />}
        />
        <StatisticsCard
          precision={1}
          value={data.averageInfoFounded}
          suffix="/ 10"
          label={t('statistics.infoFound')}
          tooltipTitle={t('statistics.infoFoundTooltip')}
          prefix={<SearchOutlined />}
        />
      </Row>
      <Row gutter={16} style={{ marginBottom: 32, marginLeft: 4 }}>
        <LineChart chart={data.chart || []} />
      </Row>
      <UnansweredQuestions dataSource={data.missingData} />
    </div>
  )
}

export default Statistics
