import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppConfig } from '../../../../hooks/appHook'

interface IProps {
  userName: string
}

const CardHeader = ({ userName }: IProps) => {
  const { appName } = useAppConfig()
  const { t } = useTranslation('onboarding')

  return (
    <div className="poll-title">
      <h2 className="poll-title-label">
        {t('cardHeader.greeting')} <span className="poll-title-label-username">{userName}!</span>
      </h2>
      <p className="poll-text">{t('cardHeader.subtext', { name: appName })}</p>
    </div>
  )
}

export default memo(CardHeader)
