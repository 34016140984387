import React, { Suspense, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { ConfigProvider } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import { theme } from './theme/theme'
import { IS_PRODUCTION } from './config/consts'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import '../src/config/i18next'
import initializeI18n from './config/i18next'
import Loading from './components/UI/Loading'

IS_PRODUCTION && ReactGA.initialize('G-2JFDHFP0Z1')

function App() {
  const [i18nInitialized, setI18nInitialized] = useState(false)

  useEffect(() => {
    initializeI18n().then(() => setI18nInitialized(true))
  }, [])

  if (!i18nInitialized) {
    return <Loading />
  }

  return (
    <BrowserRouter>
      <StyleProvider hashPriority={'high'}>
        <ConfigProvider theme={theme}>
          <Suspense fallback={<Loading />}>
            <AppRouter />
          </Suspense>
        </ConfigProvider>
      </StyleProvider>
    </BrowserRouter>
  )
}

export default App
