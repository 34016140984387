import React from 'react'
import { Form, Input } from 'antd'
import TestChatButton from '../../TestChatButton/TestChatButton'
import AppPrimaryButton from '../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useErrorMessage } from '../../../../../hooks/useErrorMessage'
import { useWarningMessage } from '../../../../../hooks/useWarningMessage'
import { requiredValidator } from '../../../../../helpers/validators'
import { useAppSelector } from '../../../../../hooks/appHook'
import {
  useDeleteStorageKnowledgeRecordByIdMutation,
  useLazyGetStorageKnowledgeRecordsBySearchParamsQuery,
  useUpdateStorageKnowledgeRecordByIdMutation,
} from '../../../../../store/api/storage.api'
import { EditStorageKnowledgeRecord, StorageKnowledgeRecord } from '../../../../../types/storage.type'
import { RolesEnum } from '../../../../../config/rolesEnum'
import KnowledgeRecordsList from '../../KnowledgeRecordsList/KnowledgeRecordsList'
import { useTranslation } from 'react-i18next'

const SearchAndEditTab = () => {
  const { t } = useTranslation('knowledgeBase')

  const [searchForm] = Form.useForm()

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { user } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const [getStorageKnowledgeRecords, getStorageKnowledgeRecordsQueryResult] =
    useLazyGetStorageKnowledgeRecordsBySearchParamsQuery()

  const [updateStorageKnowledgeRecord, updateStorageKnowledgeRecordMutationResult] =
    useUpdateStorageKnowledgeRecordByIdMutation()

  const [deleteStorageKnowledgeRecord, deleteStorageKnowledgeRecordMutationResult] =
    useDeleteStorageKnowledgeRecordByIdMutation()

  /** Response message handlers */
  useWarningMessage(
    t('searchAndEditTab.warningMessage'),
    getStorageKnowledgeRecordsQueryResult.isSuccess &&
      !getStorageKnowledgeRecordsQueryResult.isLoading &&
      !getStorageKnowledgeRecordsQueryResult.data?.documents.length,
  )
  useErrorMessage(t('searchAndEditTab.loadErrorMessage'), getStorageKnowledgeRecordsQueryResult.error)
  useErrorMessage(t('searchAndEditTab.deleteErrorMessage'), deleteStorageKnowledgeRecordMutationResult.error)
  useErrorMessage(t('searchAndEditTab.addErrorMessage'), updateStorageKnowledgeRecordMutationResult.error)

  const isLoading =
    getStorageKnowledgeRecordsQueryResult.isLoading ||
    updateStorageKnowledgeRecordMutationResult.isLoading ||
    deleteStorageKnowledgeRecordMutationResult.isLoading

  const handleOnSearch = (values: { query: string }) => {
    getStorageKnowledgeRecords({ id: currentStorageId, body: { query: values.query } })
  }

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({
      id: currentStorageId,
      vectorId: item.id,
    })
    await getStorageKnowledgeRecords({
      id: currentStorageId,
      body: { query: searchForm.getFieldValue('query') },
    })
  }

  const handleEditData = async ({ value, documentId, chunkIndex }: EditStorageKnowledgeRecord) => {
    await updateStorageKnowledgeRecord({
      storageId: currentStorageId,
      data: value,
      documentId,
      chunkIndex,
    })

    await getStorageKnowledgeRecords({ id: currentStorageId, body: { query: searchForm.getFieldValue('query') } })
  }

  return (
    <div>
      <div className="tab-header">
        <div className="header-description">
          <h2>{t('searchAndEditTab.headerTitle')}</h2>
          <p>{t('searchAndEditTab.headerDescription')}</p>
        </div>
        <TestChatButton />
      </div>
      <div className="edit-data-container">
        <Form form={searchForm} onFinish={handleOnSearch}>
          <Form.Item
            name="query"
            rules={[{ required: true, validator: requiredValidator(t('searchAndEditTab.requiredMessage')) }]}
          >
            <Input size="large" />
          </Form.Item>
          <div className="search-buttons-container">
            <Form.Item>
              <AppPrimaryButton type="primary" htmlType="submit" className="app-primary-button-medium" size="middle">
                {t('searchAndEditTab.searchButton')}
              </AppPrimaryButton>
            </Form.Item>
          </div>
        </Form>
        {user?.role == RolesEnum.ADMIN &&
          getStorageKnowledgeRecordsQueryResult.data?.params &&
          JSON.stringify(getStorageKnowledgeRecordsQueryResult.data?.params)}
        <div className="custom-separator-list-container">
          <KnowledgeRecordsList
            records={getStorageKnowledgeRecordsQueryResult.data?.documents || []}
            loading={isLoading}
            onPressDelete={handleDeleteItem}
            onPressEdit={handleEditData}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchAndEditTab
