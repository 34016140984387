import React, { memo } from 'react'
import './aiHelperHeader.less'
import { useTranslation } from 'react-i18next'
import { useAppConfig } from '../../hooks/appHook'

interface Props {
  title: string
  paragraph?: string
}

const AiHelperHeader = ({ title, paragraph }: Props) => {
  const { t } = useTranslation('components')
  const { appName } = useAppConfig()
  const defaultParagraph = t('aiHelperHeader.defaultParagraph', { name: appName })
  return (
    <div className="ai-helper-header">
      <h1>{title}</h1>
      {paragraph && <p>{paragraph || defaultParagraph}</p>}
    </div>
  )
}

export default memo(AiHelperHeader)
