import React, { memo } from 'react'
import { Form, FormInstance, Input } from 'antd'
import { requiredValidator } from '../../../../helpers/validators'
import './ceAIAgentForm.less'
import { useTranslation } from 'react-i18next'

interface IProps {
  aiAgentForm: FormInstance
  onSubmitForm: (values: { name: string }) => void
}
const CEAIAgentForm = ({ aiAgentForm, onSubmitForm }: IProps) => {
  const { t } = useTranslation('aiAgent')
  t('aiAgentPageHeader.createNewAIAgentModal.cEAIAgentForm.name')

  return (
    <Form
      name="create_edit_ai_agent_form"
      form={aiAgentForm}
      layout="vertical"
      className="create-ai-agent-form"
      onFinish={onSubmitForm}
    >
      <Form.Item
        label={t('aiAgentPageHeader.createNewAIAgentModal.cEAIAgentForm.name')}
        name="name"
        rules={[
          {
            required: true,
            validator: requiredValidator(t('aiAgentPageHeader.createNewAIAgentModal.cEAIAgentForm.requiredMessage')),
          },
        ]}
        style={{ marginBottom: '8px' }}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default memo(CEAIAgentForm)
