import React, {memo} from 'react';
import { ChartOptions } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useChartData } from './hooks/useChartData'
import { DataPoint } from '../../../../../../types/conversation.type'
import 'chart.js/auto'

interface LineChartProps {
  chart: DataPoint[];
}

const LineChart = ({ chart }: LineChartProps) => {
  const chartData = useChartData(chart);

  const chartOptions: ChartOptions<'line'> = {
    scales: {
      y: {
        min: 0,
      },
    }
  };

  return (
      <Line data={chartData} options={chartOptions} />
  );
};

export default memo(LineChart);
