export const removeAttributes = (str: string) => {
  const regex = /data-agent-id="[^"]*"|data-host="[^"]*"/g
  return str.replace(regex, '')
}

export const addDataAgentIdAttribute = (str: string, agentId: string) => {
  if (agentId) {
    return str.replace(/(src="[^"]*")/, `data-agent-id="${agentId}"`)
  } else {
    return str
  }
}

/**
 * Truncates the text to the specified length and adds ellipsis (...) at the end.
 * @param text - The text to be truncated.
 * @param maxLength - The maximum length of the text.
 * @returns The truncated text with ellipsis if it exceeds maxLength.
 */
export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...'
  }

  return text
}
