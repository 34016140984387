import React, {memo} from 'react';
import { Col, Card, Statistic } from 'antd';
import LabeledTooltip from '../../../../../../components/UI/LabeledTooltip/LabeledTooltip'

interface StatisticsCardProps {
  label: string;
  tooltipTitle: string;
  value?: number;
  precision?: number;
  prefix: React.ReactNode;
  suffix: React.ReactNode;
  bordered?: boolean;
  isStyled?: boolean;
}

const colorByScore = (score: number | undefined) => {
  if (!score) {
    return ''
  }
  if (score < 5) {
    return 'red'
  }
  if (score < 7.5) {
    return '#fa8c16'
  }
  return 'green'
}

const StatisticsCard = ({
  label,
  tooltipTitle,
  value,
  precision = 0,
  prefix,
  suffix,
  bordered = false,
  isStyled = true
}: StatisticsCardProps) => {
  return (
    <Col span={8}>
      <Card bordered={bordered}>
        <Statistic
          title={
            <LabeledTooltip
              label={label}
              tooltipTitle={tooltipTitle}
            />
          }
          value={value}
          precision={precision}
          prefix={prefix}
          suffix={suffix}
          valueStyle={{ color: isStyled ? colorByScore(value) : '#0A2540' }}
        />
      </Card>
    </Col>
  );
};

export default memo(StatisticsCard);
