import { useMemo } from 'react'
import { DataPoint } from '../../../../../../../types/conversation.type'

export const useChartData = (data: DataPoint[]) => {
  return useMemo(() => ({
    datasets: [
      {
        label: 'Satisfactory',
        data: data.map((d) => ({
          x: d.date,
          y: d.averageSatisfactory,
        })),
        fill: false,
      },
      {
        label: 'Info Found',
        data: data.map((d) => ({
          x: d.date,
          y: d.averageInfoFounded,
        })),
        fill: false,
      },
    ],
  }), [data]);
};
