import React, {memo, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  icon: ReactNode;
  redirectUrl: string;
  title: string;
  description: string;
  children?: ReactNode;
}

const LinkCard = ({redirectUrl, icon, title, description, children}: IProps) => {
  return <Link className="button" to={redirectUrl}>
    {icon}
    <div className="button-text-wrapper">
      <h3>{title}</h3>
      <p>
        {description}
        {children}
      </p>
    </div>
  </Link>
}

export default memo(LinkCard);
