import React, { useCallback, useRef, useEffect } from 'react'
import { notification } from 'antd'
// https://github.com/openai/openai-realtime-api-beta/pull/11 temporary use patched version.
// TODO: remove after PR are accepted by OpenAI
import { RealtimeClient } from '../../lib/realtime-api-beta'
import { WavRecorder, WavStreamPlayer } from '../../lib/wavtools'
import './openaiRealtime.less'
import { RealtimeEventTypeEnum } from '../../types/enums/realtime-event-type.enum'

const { REACT_APP_REALTIME_SERVER_URL } = process.env

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string
  source: 'client' | 'server'
  count?: number
  event: { [key: string]: any }
}
type IProps = {
  conversationId: string
  refetchMessages: () => void
}

const OpenaiRealtimeEnabled = ({ conversationId, refetchMessages }: IProps) => {
  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }))
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }))
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({ url: `${REACT_APP_REALTIME_SERVER_URL}?conversationId=${conversationId}` }),
  )
  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    try {
      const client = clientRef.current
      const wavRecorder = wavRecorderRef.current
      const wavStreamPlayer = wavStreamPlayerRef.current

      // Connect to microphone
      await wavRecorder.begin()

      // Connect to audio output
      await wavStreamPlayer.connect()

      // Connect to realtime API
      await client.connect()
      // if (!client.isConnected()) {
      //   return
      // }
      await wavRecorder.record((data) => {
        if (client.isConnected()) {
          client.appendInputAudio(data.mono)
        } else {
          notification.error({ message: 'Conversation connection error. Try again later' })
          disconnectConversation()
          // wavRecorder.end()
          return
        }
      })
    } catch (e) {
      console.log('connecting error', e)
    }
  }, [])

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    try {
      //disable voice mode

      const client = clientRef.current
      client.disconnect()

      const wavRecorder = wavRecorderRef.current
      await wavRecorder.end()

      const wavStreamPlayer = wavStreamPlayerRef.current
      await wavStreamPlayer.interrupt()
    } catch (e) {
      console.log('disconnecting error', e)
    }
  }, [])

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current
    const client = clientRef.current

    try {
      // handle realtime events from client + server for event logging
      client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
        if (
          realtimeEvent?.event?.type === RealtimeEventTypeEnum.audioTranscriptDoneFromAssistant ||
          realtimeEvent?.event?.type === RealtimeEventTypeEnum.audioTranscriptDoneFromCustomer
        ) {
          refetchMessages()
        }
      })

      client.on('error', () => {
        notification.error({ message: 'Oops.. Something went wrong. Please try again' })
        disconnectConversation()
      })

      client.on('close', () => {
        notification.error({ message: 'Oops.. Client was closed, try again please' })
      })

      client.on('conversation.interrupted', async () => {
        const trackSampleOffset = await wavStreamPlayer.interrupt()
        if (trackSampleOffset?.trackId) {
          const { trackId, offset } = trackSampleOffset
          await client.cancelResponse(trackId, offset)
        }
      })
      client.on('conversation.updated', async ({ item, delta }: any) => {
        if (delta?.audio) {
          wavStreamPlayer.add16BitPCM(delta.audio, item.id)
        }
        if (item.status === 'completed' && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000)
          item.formatted.file = wavFile
        }
        // console.log('conversation.updated', { item, delta })
      })
    } catch (e) {
      notification.error({ message: 'Oops.. Something went wrong. Please try again' })
    }

    return () => {
      // cleanup; resets to defaults
      client.reset()
    }
  }, [])

  useEffect(() => {
    connectConversation()
    return () => {
      disconnectConversation()
    }
  }, [])

  return <div style={{ visibility: 'hidden', display: 'none' }}></div>
}

export default OpenaiRealtimeEnabled
