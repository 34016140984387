import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks/appHook'
import { AccountRolesEnum } from '../../../../config/rolesEnum'
import InviteToTeamButton from '../InviteToTeamButton'
import { ACCOUNTS_ROUTE, TEAM_ROUTE } from '../../../../config/consts'
import './teamInviteControl.less'

const TeamInviteControl = () => {
  const location = useLocation()
  const { accountRole } = useAppSelector((state) => state.profile.user)

  const renderHeaderItem = useMemo(() => {
    if (accountRole !== AccountRolesEnum.ADMIN) {
      return null
    }

    const routesWithInviteButton = [TEAM_ROUTE, ACCOUNTS_ROUTE]
    return routesWithInviteButton.some((route) => location.pathname.includes(route))
      ? <InviteToTeamButton />
      : null
  }, [location.pathname, accountRole])

  return <div className="team-invite-control">{renderHeaderItem}</div>
}

export default TeamInviteControl
