import React, { useCallback, useState, memo } from 'react'
import { Input, Modal } from 'antd'
import { useAppSelector } from '../../../hooks/appHook'
import { useDispatch } from 'react-redux'
import { useAppStateActions } from '../../../hooks/actions'
import { useTranslation } from 'react-i18next'

interface IProps {
  handleImageLinkAdd: (link: string) => void
}

const AddImageByLinkModal = ({ handleImageLinkAdd }: IProps) => {
  const dispatch = useDispatch()

  /** Store */
  const { isAddImageByUrlModalOpen } = useAppSelector((state) => state.appState.modals)

  /** State */
  const [imageLink, setImageLink] = useState('')

  /** Use custom hooks */
  const { setAddImageByUrlModalOpen } = useAppStateActions()

  const { t } = useTranslation('components')

  const onPressOk = useCallback(() => {
    if (handleImageLinkAdd) {
      handleImageLinkAdd(imageLink)
      setImageLink('')
      dispatch(setAddImageByUrlModalOpen(false))
    }
  }, [handleImageLinkAdd, setAddImageByUrlModalOpen, imageLink])

  const onPressCancel = useCallback(() => {
    setImageLink('')
    dispatch(setAddImageByUrlModalOpen(false))
  }, [setAddImageByUrlModalOpen])

  return (
    <Modal
      title={t('ui.modals.addImageByLinkModal.title')}
      open={isAddImageByUrlModalOpen}
      onOk={onPressOk}
      onCancel={onPressCancel}
    >
      <Input
        value={imageLink}
        onChange={(e) => setImageLink(e.target.value)}
        placeholder={t('ui.modals.addImageByLinkModal.placeholder')}
      />
      {imageLink && (
        <img
          src={imageLink}
          alt={t('ui.modals.addImageByLinkModal.previewAlt')}
          style={{ width: '100%', height: 'auto', marginTop: 10 }}
        />
      )}
    </Modal>
  )
}

export default memo(AddImageByLinkModal)
