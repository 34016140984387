import React, { memo } from 'react'
import { Form, FormInstance, Input } from 'antd'
import { requiredValidator } from '../../../../../helpers/validators'
import LanguageSelect from '../../../../../components/LanguageSelect/LanguageSelect'
import { EditStorageRequest } from '../../../../../types/storage.type'
import '../storageForm.less'
import './createStorageForm.less'
import { useTranslation } from 'react-i18next'

interface IProps {
  storageForm: FormInstance
  language: string
  setLanguage: (lang: string) => void
  onSubmitForm: (values: EditStorageRequest) => void
}
const CreateStorageForm = ({ storageForm, language, setLanguage, onSubmitForm }: IProps) => {
  const { t } = useTranslation('knowledgeBase')

  return (
    <Form
      name="create_edit_storage_form"
      form={storageForm}
      layout="vertical"
      className="storage-form create-storage-form"
      style={{ padding: '20px 0' }}
      onFinish={onSubmitForm}
    >
      <div className="storage-form-main-settings">
        <Form.Item
          label={t('knowledgeBasePageHeader.createNewStorageModal.createStorageForm.nameLabel')}
          name="name"
          rules={[
            {
              required: true,
              validator: requiredValidator(
                t('knowledgeBasePageHeader.createNewStorageModal.createStorageForm.nameRequired'),
              ),
            },
          ]}
          style={{ marginBottom: '8px', maxWidth: '100%' }}
        >
          <Input />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label={t('knowledgeBasePageHeader.createNewStorageModal.createStorageForm.languageLabel')}
          name="language"
        >
          <LanguageSelect initialLang={language} setLanguage={setLanguage} />
        </Form.Item>
      </div>
    </Form>
  )
}

export default memo(CreateStorageForm)
