import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Table, Tag } from 'antd'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import { useGetConversationListQuery } from '../../store/api/conversation.api'
import './conversations.less'
import { useTranslation } from 'react-i18next'
import { useAppConfig } from '../../hooks/appHook'

const Conversations = () => {
  const { t } = useTranslation('conversations')
  const { data: dataSource, isLoading } = useGetConversationListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const { appName } = useAppConfig()

  const columns = [
    {
      title: t('dateCreated'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string, record: any) => {
        const formatDate = moment(value).format('D/MM/YYYY')
        const formatTime = moment(value).format('h:mm a')
        return (
          <Link to={`${record.id}`} className="conversation-url">
            <p>
              {formatDate} <span>{formatTime}</span>
            </p>
            {record.unread && record.unread === true && (
              <Tag color="green" style={{ height: 22 }}>
                {t('new')}
              </Tag>
            )}
          </Link>
        )
      },
    },
    {
      title: t('satisfactory'),
      dataIndex: ['analytics', 'satisfactory'],
      key: 'satisfactory',
    },
    {
      title: t('infoFound'),
      dataIndex: ['analytics', 'infoFounded'],
      key: 'infoFounded',
    },
    {
      title: t('userName'),
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: t('customerName'),
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: t('customerEmail'),
      dataIndex: 'customerEmail',
      key: 'customerEmail',
    },
    {
      title: t('messagesCount'),
      dataIndex: ['messagesCount'],
      key: 'messagesCount',
    },
  ]

  return (
    <>
      <AiHelperHeader title={t('headerTitle')} paragraph={t('defaultParagraph', { name: appName })} />
      <div id="container">
        <Table
          id="table"
          dataSource={dataSource}
          columns={columns}
          rowKey={'id'}
          loading={isLoading}
          pagination={{ position: ['bottomCenter'] }}
          scroll={{ y: 400, x: 800 }}
        />
      </div>
    </>
  )
}

export default Conversations
