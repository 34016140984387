import React from 'react'
import { Button, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  title: string
  description: string
  confirmHandler: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined, ...args: any) => void
  isLoading: boolean
}

const ConfirmedDeleteButton = ({ title, description, confirmHandler, isLoading }: IProps) => {
  const { t } = useTranslation('components')

  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={confirmHandler}
      okButtonProps={{ loading: isLoading }}
      okText={t('ui.confirmedDeleteButton.okText')}
      cancelText={t('ui.confirmedDeleteButton.cancelText')}
    >
      <Button type="primary" danger>
        {t('ui.confirmedDeleteButton.deleteButtonText')}
      </Button>
    </Popconfirm>
  )
}

export default ConfirmedDeleteButton
