import React, { ReactElement, useMemo } from 'react'
import { IAccount } from '../../../types/account.type'
import { RolesEnum } from '../../../config/rolesEnum'
import GoogleConnectionButton from './GoogleConnectionButton'
import { IUser } from '../../../types/profile.type'
import IntegrationsSection from './IntegrationsSection'

interface IProps {
  account: IAccount
  user: IUser
}

// Function to get general services (this can be extended later)
const getGeneralServices = (): ReactElement[] => {
  return []
}

// Function to get services for admin
const getServicesForAdmin = (account: IAccount): ReactElement[] => {
  const generalServices = getGeneralServices()

  return [
    <GoogleConnectionButton key="google" account={account} />,
    ...generalServices,
  ]
}

const AvailableConnectedServices = ({ account, user }: IProps) => {
  // Memoize the general services and admin services to optimize re-renders
  const generalServices = useMemo(() => getGeneralServices(), [])
  const servicesForAdmin = useMemo(() => getServicesForAdmin(account), [account])

  // Function to render services based on user role
  const renderServices = () => {
    if (user.role === RolesEnum.USER && generalServices.length) {
      return <IntegrationsSection>{generalServices.map((el) => el)}</IntegrationsSection>
    }

    if (user.role === RolesEnum.ADMIN && servicesForAdmin.length) {
      return <IntegrationsSection>{servicesForAdmin.map((el) => el)}</IntegrationsSection>
    }

    return null
  }

  return renderServices()
}

export default React.memo(AvailableConnectedServices)
