import { ChatConfigurations } from './public.type'
import { CompanyCaseOption, CompanySizeOption } from './onboarding.type'

export enum AccountStatusEnum {
  ONBOARD = 'onboarding',
  EMPTY = 'empty',
  TRIAL = 'trial',
  PAID = 'paid',
}

export enum SubscriptionPlan {
  BASIC = 'basic'
}

// TODO: deprecated
interface DEPRECATED_Chat {
  chat?: ChatConfigurations
}

// TODO: deprecated
interface DEPRECATED_Bot {
  botSettings?: {
    customTarget?: null | string
    distanceThreshold?: number | null
    resultsQuantity?: number | null
    systemMessage?: string | null
    databaseLanguage?: string | null
  }
  data?: object
}

export interface IAccount extends DEPRECATED_Chat, DEPRECATED_Bot {
  id: string
  name?: string
  companyName?: string
  companySize?: CompanySizeOption
  useCase?: CompanyCaseOption
  status: AccountStatusEnum
  defaultAgentId?: string
  plan: SubscriptionPlan
  billingCycle?: string
  hasStripe: boolean
  planStartedAt?: string
  planCancelAtPeriodEnd?: boolean
  planCancelsAt?: string
}

export interface AccountState {
  account: IAccount
  isIntegrationActionMade: boolean
}
