import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-http-backend'
import { fetchAppConfig } from '../api/fetchAppConfig'
import localStorageBackend from 'i18next-localstorage-backend'

const nameSpaces = [
  'common',
  'accounts',
  'profile',
  'profileEdit',
  'aiAgent',
  'homepage',
  'conversations',
  'knowledgeBase',
  'auth',
  'chat',
  'guest',
  'onboarding',
  'components',
]

const initializeI18n = async () => {
  const CONFIG_URL = process.env.REACT_APP_CONFIG_URL || 'https://conf.ioni.ai'
  const appConfig = await fetchAppConfig()

  const currentDomain = window.location.origin.split('//')[1]
  const primaryLoadPath = `${CONFIG_URL}/${currentDomain}/locales/{{lng}}/{{ns}}.json`

  const fallbackLoadPath = '/locales/{{lng}}/{{ns}}.json'

  const determineLoadPath = async () => {
    const testUrl = primaryLoadPath.replace('{{lng}}', 'en').replace('{{ns}}', 'common')
    try {
      const response = await fetch(testUrl, { method: 'HEAD' })
      if (response.ok) {
        return primaryLoadPath
      }
    } catch (error) {
      console.warn('Primary load path not available, using fallback.', error)
    }
    return fallbackLoadPath
  }

  const loadPath = await determineLoadPath()

  await i18next
    .use(initReactI18next)
    .use(backend)
    .init({
      lng: appConfig?.localization?.defaultLanguage || 'en',
      fallbackLng: appConfig?.localization?.defaultLanguage || 'en',
      supportedLngs: appConfig?.localization?.supportedLanguages || ['en'],
      ns: appConfig?.localization?.nameSpaces || nameSpaces,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        backends: [localStorageBackend, backend],
        backendOptions: [
          {
            expirationTime: 60 * 60 * 1000, // 1H
          },
          {
            loadPath,
          },
        ],
      },
    })
}

export default initializeI18n
