import React, { useEffect, useState } from 'react'
import { Checkbox, Dropdown, Form, Input, MenuProps } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Icon from '../../../components/UI/Icons/Icons'
import TransparentButton from '../../../components/UI/TransparentButton/TransparentButton'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useAppConfig, useAppSelector } from '../../../hooks/appHook'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import Loading from '../../../components/UI/Loading'
import { requiredValidator } from '../../../helpers/validators'
import { useEditAgentMutation, useLazyGetAgentByIdQuery } from '../../../store/api/agent.api'
import { useTranslation } from 'react-i18next'

interface IAgentTargetRequest {
  target: {
    type: string
    notifyEmail?: string
    customText?: string
  }
}

const CallToActionTabContent = () => {
  const { t } = useTranslation('aiAgent')
  const [form] = Form.useForm()

  const { account } = useAppSelector((state) => state.account)
  const { currentAgentId, agent } = useAppSelector((state) => state.agents)
  const { appName } = useAppConfig()

  const [getAgent, getAgentQueryResult] = useLazyGetAgentByIdQuery()
  const [updateAgent, updateAgentMutationResult] = useEditAgentMutation()

  const [checkedValue, setCheckedValue] = useState('in-app')
  const [isTemplateEdited, setIsTemplateEdited] = useState(false)

  useErrorMessage(t('callToActionTabContent.messages.error'), updateAgentMutationResult.error)
  useSuccessMessage(t('callToActionTabContent.messages.success'), updateAgentMutationResult.isSuccess)

  const onChooseTemplate = (text: string) => {
    form.setFieldValue('template', text)
    form.validateFields()
  }

  const handleCheckboxChange = (eventcheckedValue: CheckboxChangeEvent) => {
    if (eventcheckedValue.target.value === 'custom') {
      setIsTemplateEdited(false)
    }

    setCheckedValue(eventcheckedValue.target.value)
    form.validateFields()
  }

  const onAccountUpdate = async (values: { template: string; notifyEmail?: string }) => {
    const request: IAgentTargetRequest = {
      target: {
        type: ['in-app', 'notify'].includes(checkedValue) ? checkedValue : 'custom',
        customText: values.template || '',
      },
    }
    if (values.notifyEmail) {
      request.target.notifyEmail = values.notifyEmail
    }
    updateAgent({
      id: currentAgentId,
      body: request,
    })
    setIsTemplateEdited(false)
  }

  useEffect(() => {
    if (currentAgentId) {
      getAgent({ id: currentAgentId })
    }
  }, [currentAgentId])

  useEffect(() => {
    if (agent?.target) {
      setCheckedValue(['in-app', 'notify'].includes(agent.target.type) ? agent.target.type : 'custom')
      form.setFieldsValue({
        notifyEmail: agent.target.notifyEmail,
        template: agent.target.customText,
      })
    }
  }, [agent])

  const items: MenuProps['items'] = [
    {
      label: (
        <p style={{ width: '100%' }} onClick={() => onChooseTemplate(t('callToActionTabContent.templates.calendly'))}>
          {t('callToActionTabContent.templates.calendly')}
        </p>
      ),
      key: 'calendly',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <p style={{ width: '100%' }} onClick={() => onChooseTemplate(t('callToActionTabContent.templates.whatsapp'))}>
          {t('callToActionTabContent.templates.whatsapp')}
        </p>
      ),
      key: 'whatsapp',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <p style={{ width: '100%' }} onClick={() => onChooseTemplate(t('callToActionTabContent.templates.phone'))}>
          {t('callToActionTabContent.templates.phone')}
        </p>
      ),
      key: 'phone',
    },
  ]

  if (getAgentQueryResult.isLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    )
  }

  return (
    <div className="call-to-action-tab">
      <h2>{t('callToAction')}</h2>
      <p>{t('callToActionTabContent.description')}</p>
      <Form form={form} onFinish={onAccountUpdate} layout="vertical">
        <Checkbox.Group value={[checkedValue]} className="checkbox-group-container">
          <div className="checkbox-group-item">
            <h3>{t('callToActionTabContent.chatInIONI', { name: appName })}</h3>
            <div>
              <p>{t('callToActionTabContent.chatInIONIDescription')}</p>
              <Checkbox value="in-app" onChange={handleCheckboxChange} />
            </div>
          </div>
          <div className="checkbox-group-item">
            <h3>{t('callToActionTabContent.notifyAgent')}</h3>
            <div>
              <p>{t('callToActionTabContent.notifyAgentDescription')}</p>
              <Checkbox value="notify" onChange={handleCheckboxChange} />
            </div>

            {checkedValue === 'notify' ? (
              <Form.Item
                label={t('callToActionTabContent.notifyEmail')}
                name="notifyEmail"
                rules={[
                  { required: true, validator: requiredValidator(t('validators.notifyEmailRequired')) },
                  { type: 'email', message: t('validators.invalidEmail') },
                ]}
                style={{ marginTop: '12px', marginBottom: 0, width: '100%' }}
              >
                <Input />
              </Form.Item>
            ) : null}
          </div>
          <div className="checkbox-group-item">
            <h3>{t('callToActionTabContent.customCTA')}</h3>
            <div>
              <p>{t('callToActionTabContent.customCTADescription')}</p>
              <Checkbox value="custom" onChange={handleCheckboxChange} />
            </div>
          </div>
        </Checkbox.Group>

        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement={'topLeft'}
          overlayStyle={{ width: 400, paddingLeft: 100 }}
        >
          <div className="template-menu-title">
            <h3>{t('callToActionTabContent.customCTASamples')}</h3>
            <Icon name="arrowDown" />
          </div>
        </Dropdown>
        <Form.Item
          name="template"
          initialValue={
            account.botSettings?.customTarget && !['in-app', 'notify'].includes(account.botSettings.customTarget)
              ? account.botSettings?.customTarget
              : ''
          }
          rules={[
            checkedValue === 'custom' && isTemplateEdited
              ? { required: true, validator: requiredValidator(t('validators.templateRequired')) }
              : {},
          ]}
        >
          <Input.TextArea
            className="template-textarea"
            autoSize={{ minRows: 4, maxRows: 6 }}
            disabled={checkedValue !== 'custom'}
            onChange={() => setIsTemplateEdited(true)}
          ></Input.TextArea>
        </Form.Item>
        <TransparentButton
          onClick={() => setIsTemplateEdited(true)}
          className="transparent-button-large"
          title={t('brandingTabContent.saveChanges')}
          htmlType="submit"
        />
      </Form>
    </div>
  )
}

export default CallToActionTabContent
