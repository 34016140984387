import React, {memo} from 'react'
import { Alert, Form } from 'antd'
import Rules from './components/Rules/Rules'
import LanguageSelect from '../../../../../../../components/LanguageSelect/LanguageSelect'

interface IProps {
  language: string;
  setLanguage: (lang: string) => void
}

const LanguageSettings = ({language, setLanguage}: IProps) => {
  return <div className="header-description">
    <h2>Language Settings</h2>
    <Alert
      message="We recommend creating unique storage for each language to handle requests in different languages."
      type="info"
      style={{ marginTop: 10, maxWidth: '90%' }}
    />
    <Rules />
    <Form.Item label="Language" name="language"  style={{ marginBottom: '8px' }}>
      <LanguageSelect initialLang={language} setLanguage={setLanguage} />
    </Form.Item>
  </div>
}

export default memo(LanguageSettings)
