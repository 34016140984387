const CACHE_KEY = 'appConfigCache'
const CACHE_EXPIRATION = 2 * 60 * 1000 // 2 min
const CONFIG_URL = process.env.REACT_APP_CONFIG_URL || 'https://conf.ioni.ai'

const REMOTE_CONFIG_URL = `${CONFIG_URL}/${window.location.origin.split('//')[1]}/config.json`
const LOCAL_CONFIG_PATH = '/config.json'

export const fetchAppConfig = async () => {
  try {
    const cachedData = localStorage.getItem(CACHE_KEY)
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData)

      if (Date.now() - timestamp < CACHE_EXPIRATION) {
        return data
      }
    }

    const response = await fetch(REMOTE_CONFIG_URL)
    if (!response.ok) {
      throw new Error('Remote config unavailable')
    }

    const data = await response.json()
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        data,
        timestamp: Date.now(),
      }),
    )

    return data
  } catch (error) {
    try {
      const response = await fetch(LOCAL_CONFIG_PATH)
      if (!response.ok) {
        throw new Error('Local config unavailable')
      }

      const data = await response.json()
      localStorage.setItem(
        CACHE_KEY,
        JSON.stringify({
          data,
          timestamp: Date.now(),
        }),
      )

      return data
    } catch (localError) {
      console.error('Error loading configuration:', localError)
      return {}
    }
  }
}
