import React, { useEffect, useState } from 'react'
import { Tabs, TabsProps } from 'antd'
import TestChatButton from '../../TestChatButton/TestChatButton'
import AddHtmlOrTextTab from './components/tabs/AddHtmlOrTextTab/AddHtmlOrTextTab'
import AddByUrlTab from './components/tabs/AddByUrlTab/AddByUrlTab'
import AddFileTab from './components/tabs/AddFileTab/AddFileTab'
import AddFileByUrlTab from './components/tabs/AddFileByUrlTab/AddFileByUrlTab'
import { useTranslation } from 'react-i18next'

type AddNewDataTabProps = {
  setKnowledgeBasePage: (key: string) => void
  addNewDataActivePage: string
}

const AddNewDataTab = ({ setKnowledgeBasePage, addNewDataActivePage }: AddNewDataTabProps) => {
  const { t } = useTranslation('knowledgeBase')
  const [activeKey, setActiveKey] = useState<string>('1')

  useEffect(() => {
    setActiveKey(addNewDataActivePage)
  }, [addNewDataActivePage])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('addNewDataTab.addHtmlOrText'),
      children: <AddHtmlOrTextTab />,
    },
    {
      key: '2',
      label: t('addNewDataTab.addDocument'),
      children: <AddFileTab setKnowledgeBasePage={setKnowledgeBasePage} />,
    },
    {
      key: '3',
      label: t('addNewDataTab.addDataByUrl'),
      children: <AddByUrlTab />,
    },
    {
      key: '4',
      label: t('addNewDataTab.addDocumentByUrl'),
      children: <AddFileByUrlTab setKnowledgeBasePage={setKnowledgeBasePage} />,
    },
  ]

  return (
    <div className="add-new-data-tab">
      <div className="tab-header">
        <div className="header-description">
          <h2>{t('addNewDataTab.headerTitle')}</h2>
          <p>{t('addNewDataTab.description')}</p>
        </div>
        <TestChatButton />
      </div>
      <Tabs activeKey={activeKey} items={items} onChange={setActiveKey} destroyInactiveTabPane />
    </div>
  )
}

export default AddNewDataTab
