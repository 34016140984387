import React, { useMemo } from 'react'
import { message, Table } from 'antd'
import ConfirmedDeleteButton from '../../../../components/UI/Buttons/ConfirmedDeleteButton/ConfirmedDeleteButton'
import { useDeleteIntegrationMutation, useGetIntegrationListQuery } from '../../../../store/api/integration.api'
import Loading from '../../../../components/UI/Loading'
import './integrationsTable.less'

const IntegrationsTable = () => {
  const { data = [], isLoading } = useGetIntegrationListQuery()
  const [removeIntegration, { isLoading: isRemoveLoading }] = useDeleteIntegrationMutation()

  const confirm = (e?: React.MouseEvent<HTMLElement, MouseEvent>, id?: string) => {
    console.log(e, id)
    if (id) {
      removeIntegration(id)
    }
    message.success('Integration removed successfully').then()
  }

  const dataSource = useMemo(() =>
    data?.map((el) => ({
      key: el.id,
      email: el.email,
      createdAt: new Date(el.createdAt).toLocaleDateString('en-GB', { timeZone: 'UTC' }),
      actions: (
        <ConfirmedDeleteButton
          title="Delete the integration"
          description="Are you sure you want to delete this integration?"
          confirmHandler={(e) => confirm(e, el.id)}
          isLoading={isRemoveLoading}
        />
      ),
    })), [data, isRemoveLoading],
  )

  const columns = useMemo(() => [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
  ], [])

  if (isLoading) {
    return (
      <div style={{ margin: '2rem 0' }}>
        <Loading />
      </div>
    )
  }

  if (!data.length) {
    return null
  }

  return (
    <div className="integrations-table-wrapper">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: '100%' }}  // Enables horizontal scrolling if necessary
      />
    </div>
  )
}

export default IntegrationsTable
