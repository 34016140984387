import React, { useState } from 'react'
import { Button } from 'antd'
// https://github.com/openai/openai-realtime-api-beta/pull/11 temporary use patched version.
// TODO: remove after PR are accepted by OpenAI
import { PublicChatMode } from '../../types/enums/publick-chat-mode.enum'
import { AudioFilled } from '@ant-design/icons'
import './openaiRealtime.less'
import { Conversation } from '../../types/conversation.type'
import OpenaiRealtimeEnabled from './OpenaiRealtimeEnabled'

/**
 * Type for all event logs
 */

type IProps = {
  conversationId: string
  chatMode?: PublicChatMode
  setChatMode: (value: PublicChatMode) => void
  refetchMessages: () => void
  createConversation: () => Promise<Conversation | undefined>
}

const OpenaiRealtime = ({ conversationId, chatMode, setChatMode, refetchMessages, createConversation }: IProps) => {
  const [newConversationId, setNewConversationId] = useState<string | null>(null)

  const onEnableRealtime = async () => {
    if (!conversationId) {
      const createdConversation = await createConversation()
      if (createdConversation?.id) {
        setNewConversationId(createdConversation.id)
      }
      if (!createdConversation?.storageId) {
        return
      }
    }
    setChatMode(PublicChatMode.voice)
  }

  return (
    <div>
      <Button
        className={chatMode === PublicChatMode.voice ? 'disconnect-button' : 'connect-button'}
        onClick={() => (chatMode === PublicChatMode.voice ? setChatMode(PublicChatMode.chat) : onEnableRealtime())}
      >
        <AudioFilled className="microphone-icon" />
      </Button>
      {chatMode === PublicChatMode.voice && (
        <OpenaiRealtimeEnabled
          conversationId={conversationId || newConversationId || ''}
          refetchMessages={refetchMessages}
        />
      )}
    </div>
  )
}

export default OpenaiRealtime
