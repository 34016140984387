import React from 'react'
import { Popconfirm, Table } from 'antd'
import moment from 'moment'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from '../../../hooks/appHook'
import { useGetUsersQuery } from '../../../store/api/users.api'
import { useDeleteProfileMutation } from '../../../store/api/profile.api'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { AccountRolesEnum } from '../../../config/rolesEnum'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import AiHelperHeader from '../../../components/AIHelperHeader/aiHelperHeader'
import TeamInviteControl from './TeamInviteControl/TeamInviteControl'
import '../accounts.less'

interface DataType {
  name: string
  email: string
  _id: string
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'accountRole',
    key: 'role',
  },
  {
    title: 'Last Login',
    dataIndex: 'lastLoginAt',
    key: 'lastLogin',
    render: (value) => (value ? moment(value).format('MMM Do yyyy h:mm a') : 'Invitation pending...'),
  },
]

const Team = () => {
  const { user } = useAppSelector((state) => state.profile)
  const users = useAppSelector((state) => state.users.users)
  const { isLoading } = useGetUsersQuery(undefined, { refetchOnMountOrArgChange: true })

  const [userDelete, { error, isSuccess }] = useDeleteProfileMutation()

  const actionColumn = [
    {
      title: 'Actions',
      dataIndex: '',
      render: (_: any, record: { _id: string }) =>
        record._id !== user.id && (
          <Popconfirm title="Sure you want to delete?" onConfirm={() => handleDeleteUser(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ),
    },
  ]

  const handleDeleteUser = (userId: string) => {
    userDelete(userId)
  }

  useSuccessMessage('User was successfully deleted', isSuccess)
  useErrorMessage('Server error!', error)

  return (
    <>
      <AiHelperHeader title="My team" paragraph="" />
      <TeamInviteControl />
      <div id="container">
        <Table
          className="admin-table"
          columns={user.accountRole === AccountRolesEnum.ADMIN ? [...columns, ...actionColumn] : columns}
          rowKey={'_id'}
          loading={isLoading}
          pagination={{ position: ['bottomCenter'] }}
          dataSource={users}
          scroll={{ y: 400, x: 800 }}
        />
      </div>
    </>
  )
}

export default Team
