import React from 'react';
import { Tooltip, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';

interface InfoTableProps<T extends object> {
  title: string;
  tooltip?: string;
  dataSource: T[];
  columns: ColumnsType<T>;
  pagination?: false | TablePaginationConfig;
}

const InfoTable = <T extends object>({
  title,
  tooltip,
  dataSource,
  columns,
  pagination,
}: InfoTableProps<T>) => (
  <div>
    <h2>
      {title}{' '}
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </h2>
    <Table dataSource={dataSource} columns={columns} pagination={pagination} />
  </div>
);

export default InfoTable;
