import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface LabeledTooltipProps {
  label: string; // текст метки
  tooltipTitle: string; // текст для подсказки
}

const LabeledTooltip = ({ label, tooltipTitle }: LabeledTooltipProps) => {
  return (
    <div>
      {label}{' '}
      <Tooltip title={tooltipTitle}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
};

export default LabeledTooltip;
