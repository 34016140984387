import React, { memo } from 'react'
import { Form, FormInstance, Input } from 'antd'
import LanguageSettings from '../../tabs/SettingsTab/components/LanguageSettings/LanguageSettings'
import OtherSettings from '../../tabs/SettingsTab/components/OtherSettings/OtherSettings'
import { EditStorageRequest } from '../../../../../types/storage.type'
import { requiredValidator } from '../../../../../helpers/validators'
import '../storageForm.less'
import './editStorageForm.less'

interface IProps {
  storageForm: FormInstance
  language: string
  setLanguage: (lang: string) => void
  onSubmitForm: (values: EditStorageRequest) => void
}

import { useTranslation } from 'react-i18next'

const EditStorageForm = ({ storageForm, language, setLanguage, onSubmitForm }: IProps) => {
  const { t } = useTranslation('knowledgeBase')

  return (
    <Form
      name="create_edit_storage_form"
      form={storageForm}
      layout="vertical"
      className="storage-form edit-storage-form"
      onFinish={onSubmitForm}
    >
      <div className="storage-form-main-settings">
        <Form.Item
          label={t('settingsTab.editStorageForm.nameLabel')}
          name="name"
          rules={[{ required: true, validator: requiredValidator(t('settingsTab.editStorageForm.nameRequired')) }]}
          style={{ marginBottom: '8px' }}
        >
          <Input />
        </Form.Item>
        <LanguageSettings language={language} setLanguage={setLanguage} />
        <OtherSettings />
      </div>
    </Form>
  )
}

export default memo(EditStorageForm)
