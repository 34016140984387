import React, { PropsWithChildren } from 'react'
import IntegrationsTable from './IntegrationsTable/IntegrationsTable'

const IntegrationsSection = ({ children }: PropsWithChildren) => {
  return (
    <div className="integrations-wrapper">
      <h2>Integrations</h2>
      <IntegrationsTable />
      {children}
    </div>
  )
}

export default IntegrationsSection
