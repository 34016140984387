import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { message } from 'antd'

export const useIntegrationSuccess = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('integration') === 'success') {
      message.success('Integration connected successfully').then(() => {
        // Remove the query parameter from the URL
        params.delete('integration')
        navigate({ search: params.toString() }, { replace: true })
      })
    }
  }, [location.search, navigate])
}
