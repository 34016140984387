import React, { memo } from 'react'
const Rules = () => {
  return (
    <ul style={{ paddingLeft: 15 }}>
      <li>
        <p>All data that you add to your Knowledge Base should remain in the same language.</p>
      </li>
      <li>
        <p>If you add data in any other language than English, please select the proper language here.</p>
      </li>
      <li>
        <p>
          If your Knowledge Base is in English, system will be able to handle queries in any language that your model
          supports.
        </p>
      </li>
      <li>
        <p>
          For <b>other languages</b> please create unique storage for each language. In this case bot will detect user
          query language and choose proper Knowledge Base to search data.
        </p>
      </li>
    </ul>
  )
}

export default memo(Rules)
