import React from 'react'
import Intro from './components/Intro/Intro'
import { useGetConversationStatsQuery } from '../../store/api/conversation.api'
import { Spin } from 'antd'
import Statistics from './components/Statistics/Statistics'
import { useAppSelector } from '../../hooks/appHook'
import './homepage.less'
import { useTranslation } from 'react-i18next'

const Homepage = () => {
  const { t } = useTranslation('homepage')
  const { user } = useAppSelector((state) => state.profile)
  const { data, isLoading } = useGetConversationStatsQuery()

  if (isLoading) {
    return <Spin size={'large'} />
  }

  return (
    <>
      <div className="left-header-item">
        <div className="ai-helper-header">
          <h1>{t('welcome', { name: user.name })}</h1>
          <p>{t('performanceMessage')}</p>
        </div>
      </div>
      {data?.count ? <Statistics data={data} /> : <Intro />}
    </>
  )
}

export default Homepage
