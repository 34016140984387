import React from 'react'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'
import { useTranslation } from 'react-i18next'

type IProps = {
  setKnowledgeBasePage: (key: string) => void
  setAddNewDataActivePage: (key: string) => void
}

const AddDocumentButton = ({ setKnowledgeBasePage, setAddNewDataActivePage }: IProps) => {
  const { t } = useTranslation('components')
  const onClick = () => {
    setKnowledgeBasePage('1')
    setAddNewDataActivePage('2')
  }
  return <TransparentButton onClick={onClick} title={t('addDocumentButton.title')} />
}

export default AddDocumentButton
